import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import Cookies from 'js-cookie';
import { IUserCardData, IUserData } from '@/@types/user';
import { ApiFetch } from '@/api';
import { admin_api_route, api_route } from '@/@types/api-routers';
import { workerInstance } from '../../workers';

const userView = Cookies.get('userView');
const domain = import.meta.env.VITE_APP_DOMAIN;
const expires = 20 / 60 / 24;

type extendUser = { loading: string; userView: boolean };
type process = {
    general: boolean;
    riskForm: boolean;
    address: boolean;
    experience: boolean;
    documentation: boolean;
    beneficiary: boolean;
    taxes: boolean;
};

const initialState: {
    card: IUserCardData &
        extendUser & {
            stepProcess: process;
            admin: boolean;
            developer: boolean;
        };
    userData: IUserData | null;
} = {
    card: {
        name: '',
        email: '',
        profilePicture: '',
        ppi: '',
        level: 0,
        progressPPI: [],
        last_login: '',
        complete: '',
        riskForm: false,
        loading: 'idl',
        userView: false,
        admin: false,
        developer: false,
        stepProcess: {
            general: false,
            riskForm: false,
            address: false,
            experience: false,
            documentation: false,
            beneficiary: false,
            taxes: false,
        },
    },
    userData: null,
};

export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    async (arg: { userTag: string | undefined; token: string }) => {
        const api_url =
            arg.userTag && userView
                ? admin_api_route.user.getProfile + `/${arg.userTag}`
                : api_route.user.getProfile;
        const response = await ApiFetch({
            url: api_url,
            method: 'GET',
            token: arg.token,
        });
        const data = response.data;
        
        if (data.administrator) {
            const token = Cookies.get('expjwt');
            if (token) {
                sessionStorage.clear();
                Cookies.set('admin_session', token, { domain, expires });
                Cookies.set('userView', "true", { domain });
            }
        }

        try {
            const workerCall = await workerInstance.handleCheckRealStep(data);
            data.realStep = workerCall;
        } catch (err) {
            console.log(err);
        }

        return data;
    },
);

const handleRemoveCookie = () => {
    Cookies.remove('expjwt', { domain });
    Cookies.remove('type', { domain });
    Cookies.remove('cookieExpiration', { domain });
    Cookies.remove('userView', { domain });
};

const formatCard = (user: IUserData) => {
    const card: (IUserCardData & extendUser & { stepProcess: process }) | any =
        {};
    const { ppiProgress } = user;
    
        if (ppiProgress && ppiProgress.length > 0) {
            let ppi_level_5 = ppiProgress.find(
                (progress: any) => progress.level == 5 && progress.inv == true,
            );
            let ppi_level_9 = ppiProgress.find(
                (progress: any) => progress.level == 9 && progress.inv == true,
            );

            card.ppi = ppi_level_9 ? '1' : ppi_level_5 ? '0.5' : '0';
        } else {
            card.ppi = '0';
        }

        card.loading = 'idle';
        card.name =
            user.name +
            ' ' +
            user.last_name +
            ' ' +
            user.mother_last_name;
        card.email = user.email;
        card.profilePicture = user.photo;
        card.riskForm = user.investor.riskForm;
        card.level = Number(user.investor.level);
        card.progressPPI = user.ppiProgress;
        card.last_login = user.last_login;
        card.complete = String(user.complete.investor.full);
        card.admin = user.administrator || userView ? true : false;
        card.developer = user.developer ? true : false;
        card.stepProcess = user.realStep;
    return card
}

export const userSlice = createSlice({
    name: 'userCard',
    initialState,
    reducers: {
        setUserCard: (state, action: PayloadAction<IUserCardData>) => {
            console.log("on Redux")
            const card: (IUserCardData & extendUser) | any = {};

            const { progressPPI } = action.payload;
            let ppi_level_5 = progressPPI.find(
                progress => progress.level == 5 && progress.inv == true,
            );
            let ppi_level_9 = progressPPI.find(
                progress => progress.level == 9 && progress.inv == true,
            );

            card.name = action.payload.name;
            card.email = action.payload.email;
            card.profilePicture = action.payload.profilePicture;
            card.ppi = ppi_level_9 ? '1' : ppi_level_5 ? '0.5' : '0';
            card.riskForm = action.payload.riskForm;
            card.level = action.payload.level;
            card.progressPPI = action.payload.progressPPI;
            card.last_login = action.payload.last_login;
            card.complete = action.payload.complete;

            Cookies.set('userView', 'true', {
                expires,
                domain,
            });

            if (card) {
                state.card = card;
            }
        },
        setUserInfo: (state, action: PayloadAction<IUserData>) => {
            setUserCard(formatCard(action.payload));
            state.userData = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUser.pending, state => {
                state.card.loading = 'pending';
            })
            .addCase(
                fetchUser.fulfilled,
                (state, action: PayloadAction<IUserData>) => {
                    const card = formatCard(action.payload)
                    state.card = card;

                    delete action.payload.realStep;
                    delete action.payload.user_hash;

                    state.userData = action.payload;
                },
            )
            .addCase(fetchUser.rejected, state => {
                console.log("here")
                handleRemoveCookie();
                state.card.loading = 'error';
            });
    },
});

export const { setUserCard, setUserInfo } = userSlice.actions;
export const UserSelector = (state: RootState) => state.user;
export default userSlice.reducer;
