import ReactDOM from 'react-dom/client';
import Router from './router';

import { ThemeProvider } from '@material-tailwind/react';

import { Provider } from 'react-redux';
import { store } from '@/redux/store';

//csss
import './assets/css/root.css';
import './assets/css/main.css';
import './assets/css/animation.css';

import * as Sentry from '@sentry/react';

if (import.meta.env.VITE_SENTRY_KEY) {
    console.log('Tacker On');
    Sentry.init({
        dsn:
            'https://65824cb1dc1fb4450c95aca778bef629@o4506990041497600.ingest.us.sentry.io/' +
            import.meta.env.VITE_SENTRY_KEY,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['https://app.expansive.mx/'],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <ThemeProvider>
        <Provider store={store}>
            <Router />
        </Provider>
    </ThemeProvider>,
);
