import { flattenDeep } from 'lodash';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import PriveRoute, { OnLoginRouter } from './privateRouter';
import { LoadingDashboardLayout } from '@/layout/Dashboard/skeleton';

import { Suspense } from 'react';
import { Spinner } from '@material-tailwind/react';

const paths = ['login', 'register'];

export const generateFlattenRoutes = (routes: any): any => {
    if (!routes) return [];
    return flattenDeep(
        routes.map(({ subRoutes, ...rest }: { subRoutes: any }) => [
            rest,
            generateFlattenRoutes(subRoutes),
        ]),
    );
};

export const renderRoutes = (mainRoutes: any) => {
    const Routes = () => {
        const layouts = mainRoutes.map(
            (element: { layout: any; routes: any }, index: any) => {
                const Layout = element.layout;
                const routes = element.routes;

                const subRoutes = generateFlattenRoutes(routes);

                return (
                    <Route key={index} element={<Layout />}>
                        {subRoutes.map((router: any, index: number) => {
                            const Component = router.component;
                            const Routes = router.routes;
                            return router.isPublic ? (
                                paths.includes(router.name) ? (
                                    <Route
                                        path={router.path}
                                        key={index}
                                        element={<OnLoginRouter />}
                                    >
                                        <Route
                                            key={index}
                                            path={router.path}
                                            element={
                                                <Suspense
                                                    fallback={<Spinner />}
                                                >
                                                    <Component />
                                                </Suspense>
                                            }
                                        />
                                    </Route>
                                ) : (
                                    <Route
                                        key={index}
                                        path={router.path}
                                        element={
                                            <Suspense fallback={<Layout />}>
                                                <Component />
                                            </Suspense>
                                        }
                                    />
                                )
                            ) : (
                                <Route
                                    path={router.path}
                                    key={index}
                                    element={<PriveRoute />}
                                >
                                    {Routes.map(
                                        (
                                            route: {
                                                component: any;
                                                hasSiderLink: any;
                                                name: any;
                                                path: any;
                                                title: any;
                                                routers: any;
                                                subLayout: any;
                                                routes: [any];
                                            },
                                            key: number,
                                        ) => {
                                            const RouteComponent =
                                                route.component;
                                            const subRoutes =
                                                route.routes || [];
                                            const SubLayout = route.subLayout;
                                            return subRoutes.length > 0 ? (
                                                <Route
                                                    key={key}
                                                    element={<SubLayout />}
                                                >
                                                    {subRoutes.map(
                                                        (
                                                            el: any,
                                                            subKey: number,
                                                        ) => {
                                                            const SubComponent =
                                                                el.component;
                                                            return (
                                                                <Route
                                                                    key={subKey}
                                                                    path={
                                                                        el.path
                                                                    }
                                                                    element={
                                                                        <Suspense
                                                                            fallback={
                                                                                <LoadingDashboardLayout />
                                                                            }
                                                                        >
                                                                            <SubComponent />
                                                                        </Suspense>
                                                                    }
                                                                />
                                                            );
                                                        },
                                                    )}
                                                </Route>
                                            ) : (
                                                <Route
                                                    key={key}
                                                    path={route.path}
                                                    element={
                                                        <Suspense
                                                            fallback={
                                                                <LoadingDashboardLayout />
                                                            }
                                                        >
                                                            <RouteComponent />
                                                        </Suspense>
                                                    }
                                                />
                                            );
                                        },
                                    )}
                                </Route>
                            );
                        })}
                    </Route>
                );
            },
        );
        return <ReactRoutes>{layouts}</ReactRoutes>;
    };
    return Routes;
};
