import { BrowserRouter as Router } from 'react-router-dom';

//context
import { renderRoutes } from './generate-routes';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import InvestmentProvider from '@/context/investmentContext';
import { RouterArray } from './routers';

const reCaptchaKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

// Just add this line
export const Routes = renderRoutes(RouterArray);

export default function router() {
    return (
            <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
                <InvestmentProvider>
                    <Router>
                        <Routes />
                    </Router>
                </InvestmentProvider>
            </GoogleReCaptchaProvider>
    );
}
