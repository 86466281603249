import axios from 'axios';

export const ApiFetch = async ({
    url,
    method,
    data,
    config,
    token,
}: {
    url: string;
    method: string;
    data?: object | string | ArrayBuffer | null;
    config?: object;
    token?: string;
}) => {
    const baseURL = import.meta.env.VITE_API;

    const authorization = token ? { Authorization: `Bearer ${token}` } : '';

    axios.defaults.baseURL = baseURL;

    return await axios({
        baseURL,
        method,
        url,
        data,
        headers: {
            'Content-Type': 'application/json',
            ...authorization,
            ...config,
        },
    });
};

export const ApiFetchFile = async ({
    url,
    method,
    data,
    config,
    token,
}: {
    url: string;
    method: string;
    data?: object | string | ArrayBuffer | null;
    config?: object;
    token?: string;
}) => {
    const baseURL = import.meta.env.VITE_API;

    const authorization = token ? { Authorization: `Bearer ${token}` } : '';

    axios.defaults.baseURL = baseURL;

    return await axios({
        baseURL,
        method,
        url,
        data,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            ...authorization,
            ...config,
        },
    });
};
