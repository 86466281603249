import { Navigate, Outlet } from 'react-router-dom';
import { ExpansiveLogin, SummaryUrl } from '@/@types/page-router';

import { useAppSelector } from '@/hooks/DispatchHook';
import { AuthSelector } from '@/redux/Slice/auth';

const PriveRoute = () => {
    const authSelect = useAppSelector(AuthSelector);

    return authSelect.token ? <Outlet /> : <Navigate to={ExpansiveLogin} />;
};

export default PriveRoute;

export const OnLoginRouter = () => {
    const authSelect = useAppSelector(AuthSelector);

    return authSelect.token ? <Navigate to={SummaryUrl} /> : <Outlet />;
};
