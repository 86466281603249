export const api_route = {
    user: {
        taxSystem: '/profile/investor/tax/system',
        getDashboard: '/user/dashboard',
        wallet: '/profile/wallet',
        getProfile: '/profile',
        editProfile: '/profile',
        saveDoc: '/profile/user/document',
    },
    investment: {
        getAll: '/user/investments',
        getSingle: '/user/investments',
        getContract: '/user/investments',
    },
    transaction: {
        getAll: '/user/transactions',
        getInterest: '/user/interests',
        download: '/user/transactions',
        getNextPayments: '/user/next/interests',
    },
    referral: {
        getReferral: '/account/referrals',
    },
};

export const admin_api_route = {
    user: {
        getDashboard: '/admin/dashboard',
        getProfile: '/admin/users',
    },
    investment: {
        getAll: '/admin/investments/user',
        getSingle: '/admin/investments',
        getContract: '/admin/investments',
    },
    transactions: {
        getAll: '/admin/transactions/user',
        getInterest: '/admin/interests/user',
        download: '/admin/transactions/download',
        getNextPayments: '/user/next/interests',
    },
    referral: {
        getReferral: '/admin/account/',
    },
};
