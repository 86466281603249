import * as React from 'react';
import { IInvestment, InvestmentContextType } from '@/@types/investment';
// import Cookies from 'js-cookie';

export const InvestmentContext = React.createContext<
    InvestmentContextType | any
>(null);

interface Props {
    children: React.ReactNode;
}

const InvestmentProvider: React.FC<Props> = ({ children }) => {
    const [investment, setInvestment] = React.useState<IInvestment | null>(
        null,
    );

    const handleUpdate = (DataToken: IInvestment | null) => {
        if (DataToken) {
            setInvestment(DataToken);
        } else {
            setInvestment(null);
        }
    };

    const handleCancel = () => {
        setInvestment(null);
    };

    const data = {
        investment,
        handleUpdate,
        handleCancel,
    };

    return (
        <InvestmentContext.Provider value={data}>
            {children}
        </InvestmentContext.Provider>
    );
};

export default InvestmentProvider;
