import { configureStore } from '@reduxjs/toolkit';
import authSlice from './Slice/auth';
import userSlice from './Slice/user';
import notifySlice from './Slice/notify';

export const store = configureStore({
    reducer: {
        auth: authSlice,
        user: userSlice,
        notify: notifySlice,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
