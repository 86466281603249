import { Navigate } from 'react-router-dom';
import { lazy } from 'react';

//pages

const Login = lazy(() =>
    import('@/pages/public/login').then(({ Login }) => ({
        default: Login,
    })),
);
const RegisterPage = lazy(() =>
    import('@/pages/public/login/register').then(({ RegisterPage }) => ({
        default: RegisterPage,
    })),
);

const Summary = lazy(() =>
    import('@/pages/home/summary').then(({ Summary }) => ({
        default: Summary,
    })),
);
const Payments = lazy(() =>
    import('@/pages/home/payments').then(({ Payments }) => ({
        default: Payments,
    })),
);
const Opportunity = lazy(() =>
    import('@/pages/home/opportunity').then(({ Opportunity }) => ({
        default: Opportunity,
    })),
);
const OpportunityDetails = lazy(() =>
    import('@/pages/home/opportunity/[slug]').then(
        ({ OpportunityDetails }) => ({
            default: OpportunityDetails,
        }),
    ),
);
const Privacy = lazy(() =>
    import('@/pages/home/privacy').then(({ Privacy }) => ({
        default: Privacy,
    })),
);
const References = lazy(() =>
    import('@/pages/home/references').then(({ References }) => ({
        default: References,
    })),
);
const Investments = lazy(() =>
    import('@/pages/home/investments').then(({ Investments }) => ({
        default: Investments,
    })),
);
const InvestmentsResume = lazy(() =>
    import('@/pages/home/investments/[id]').then(({ InvestmentResume }) => ({
        default: InvestmentResume,
    })),
);
const InvestmentCalculator = lazy(() =>
    import('@/pages/home/opportunity/process/calculator/main').then(
        ({ InvestmentCalculator }) => ({
            default: InvestmentCalculator,
        }),
    ),
);
const InvestmentContract = lazy(() =>
    import('@/pages/home/opportunity/process/contract').then(
        ({ InvestmentContract }) => ({
            default: InvestmentContract,
        }),
    ),
);

const InvestmentCompleted = lazy(() =>
    import('@/pages/home/opportunity/process/finish').then(
        ({ InvestmentCompleted }) => ({
            default: InvestmentCompleted,
        }),
    ),
);
const Profile = lazy(() =>
    import('@/pages/home/profile').then(({ Profile }) => ({
        default: Profile,
    })),
);

const FundingPage = lazy(() =>
    import('@/pages/home/funding').then(({ FundingPage }) => ({
        default: FundingPage,
    })),
);
const WithdrawalPage = lazy(() =>
    import('@/pages/home/withdrawal').then(({ WithdrawalPage }) => ({
        default: WithdrawalPage,
    })),
);
const PageFundingInvestment = lazy(() =>
    import('@/pages/home/investments/Funding').then(
        ({ PageFundingInvestment }) => ({
            default: PageFundingInvestment,
        }),
    ),
);

const WelcomePage = lazy(() =>
    import('@/pages/home/welcome').then(({ WelcomePage }) => ({
        default: WelcomePage,
    })),
);

const InvitePage = lazy(() =>
    import('@/pages/home/invite').then(({ InvitePage }) => ({
        default: InvitePage,
    })),
);

const recoveryPasswordForm = lazy(() =>
    import('@/pages/public/login/recovery/[token]').then(
        ({ recoveryPasswordForm }) => ({
            default: recoveryPasswordForm,
        }),
    ),
);

const registerNewDevice = lazy(() =>
    import('@/pages/public/login/device').then(({ registerNewDevice }) => ({
        default: registerNewDevice,
    })),
);

const notFound = lazy(() =>
    import('@/pages/public/error').then(({ notFound }) => ({
        default: notFound,
    })),
);

const RegisterDeveloper = lazy(() =>
    import('@/pages/public/login/register/developer').then(
        ({ RegisterDeveloper }) => ({
            default: RegisterDeveloper,
        }),
    ),
);

const ActivateNewAccount = lazy(() =>
    import('@/pages/public/login/activate').then(({ ActivateNewAccount }) => ({
        default: ActivateNewAccount,
    })),
);

const recoveryPassword = lazy(() =>
    import('@/pages/public/login/recovery').then(({ recoveryPassword }) => ({
        default: recoveryPassword,
    })),
);

const regulatoryPage = lazy(() =>
    import('@/pages/public/regulatorio').then(({ regulatoryPage }) => ({
        default: regulatoryPage,
    })),
);

//Layout
const DashboardLayout = lazy(() =>
    import('@/layout/Dashboard').then(({ DashboardLayout }) => ({
        default: DashboardLayout,
    })),
);

const RegulationLayout = lazy(() =>
    import('@/layout/regulation').then(({ RegulationLayout }) => ({
        default: RegulationLayout,
    })),
);

const LoginLayout = lazy(() =>
    import('@/layout/login').then(({ LoginLayout }) => ({
        default: LoginLayout,
    })),
);

const ErrorLayout = lazy(() =>
    import('@/layout/Error').then(({ ErrorLayout }) => ({
        default: ErrorLayout,
    })),
);

import {
    ExpansiveActivateAccount,
    ExpansiveInvite,
    ExpansiveLogin,
    ExpansiveRegister,
    ExpansiveRegisterDeveloper,
    ExpansiveUrlPath,
    InvestmentPath,
} from '@/@types/page-router';

const RedirectLogin = () => {
    return <Navigate to={ExpansiveLogin} />;
};

export const RouterArray = [
    {
        layout: LoginLayout,
        routes: [
            {
                name: 'base',
                title: 'base',
                component: RedirectLogin,
                path: '/',
                isPublic: true,
            },
        ],
    },
    {
        layout: LoginLayout,
        routes: [
            {
                name: 'base',
                title: 'base',
                component: RedirectLogin,
                path: '/iniciar-sesion',
                isPublic: true,
            },
        ],
    },
    {
        layout: LoginLayout,
        routes: [
            {
                name: 'login',
                title: 'Iniciar Sesión',
                component: Login,
                path: ExpansiveLogin,
                isPublic: true,
            },
        ],
    },
    {
        layout: LoginLayout,
        routes: [
            {
                name: 'register',
                title: 'Registro',
                component: RegisterPage,
                path: ExpansiveRegister,
                isPublic: true,
            },
        ],
    },
    {
        layout: LoginLayout,
        routes: [
            {
                name: 'register',
                title: 'Registro Solicitante',
                component: RegisterDeveloper,
                path: ExpansiveRegisterDeveloper,
                isPublic: true,
            },
        ],
    },
    {
        layout: LoginLayout,
        routes: [
            {
                name: 'activate',
                title: 'Activar Cuenta',
                component: ActivateNewAccount,
                path: ExpansiveActivateAccount + '/:token',
                isPublic: true,
            },
        ],
    },
    {
        layout: LoginLayout,
        routes: [
            {
                name: 'invite',
                title: 'Invitación',
                component: InvitePage,
                path: ExpansiveInvite + '/:token',
                isPublic: true,
            },
        ],
    },
    {
        layout: LoginLayout,
        routes: [
            {
                name: 'recoveryPassword',
                title: 'Recuperar contraseña',
                component: recoveryPassword,
                path: '/recuperar',
                isPublic: true,
            },
        ],
    },
    {
        layout: LoginLayout,
        routes: [
            {
                name: 'recoveryPassword',
                title: 'Recuperar contraseña',
                component: recoveryPasswordForm,
                path: '/change-password/:token',
                isPublic: true,
            },
        ],
    },
    {
        layout: LoginLayout,
        routes: [
            {
                name: 'newDevice',
                title: 'Añadir nuevo dispositivo',
                component: registerNewDevice,
                path: '/device/authorize/:token',
                isPublic: true,
            },
        ],
    },
    {
        layout: RegulationLayout,
        routes: [
            {
                name: 'regulatory',
                title: 'Estadisticas Trimestrales',
                component: regulatoryPage,
                path: '/estadisticas-trimestrales',
                isPublic: true,
            },
        ],
    },
    {
        layout: ErrorLayout,
        routes: [
            {
                name: 'error',
                title: 'Error',
                component: notFound,
                path: '*',
                isPublic: true,
            },
        ],
    },
    {
        layout: DashboardLayout,
        routes: [
            {
                name: 'dashboard',
                title: 'Dashboard',
                hasSiderLink: true,
                isPublic: false,
                path: '/dashboard',
                routes: [
                    {
                        name: 'welcome',
                        title: 'Bienvenido',
                        hasSiderLink: true,
                        component: WelcomePage,
                        path: ExpansiveUrlPath.welcome,
                    },
                    {
                        name: 'summary',
                        title: 'Resumen',
                        hasSiderLink: true,
                        component: Summary,
                        path: ExpansiveUrlPath.summary,
                    },
                    {
                        name: 'summary',
                        title: 'Resumen',
                        hasSiderLink: true,
                        component: Summary,
                        path: ExpansiveUrlPath.summary + '/:userTag',
                    },
                    {
                        name: 'opportunity',
                        title: 'Oportunidades',
                        hasSiderLink: true,
                        component: Opportunity,
                        path: ExpansiveUrlPath.opportunity,
                    },
                    {
                        name: 'opportunity',
                        title: 'Oportunidades',
                        hasSiderLink: true,
                        component: Opportunity,
                        path: 'projects' + '/:userTag',
                    },
                    {
                        name: 'opportunityDetails',
                        title: 'Oportunidades',
                        hasSiderLink: true,
                        component: OpportunityDetails,
                        path: `${ExpansiveUrlPath.opportunity}/:slug`,
                    },
                    {
                        name: 'opportunityDetails',
                        title: 'Oportunidades',
                        hasSiderLink: true,
                        component: OpportunityDetails,
                        path: `projects/:userTag/:slug`,
                    },
                    {
                        name: 'investmentsProcess',
                        title: 'Calculadora',
                        hasSiderLink: true,
                        component: InvestmentCalculator,
                        path:
                            `${ExpansiveUrlPath.investmentProcess}/:idInvestment/` +
                            InvestmentPath.calculate,
                    },
                    {
                        name: 'investmentsProcess',
                        title: 'Contrato',
                        hasSiderLink: true,
                        component: InvestmentContract,
                        path:
                            `${ExpansiveUrlPath.investmentProcess}/:idInvestment/` +
                            InvestmentPath.contract,
                    },
                    {
                        name: 'investmentsProcess',
                        title: 'Finalizar',
                        hasSiderLink: true,
                        component: InvestmentCompleted,
                        path:
                            `${ExpansiveUrlPath.investmentProcess}/:idInvestment/` +
                            InvestmentPath.finish,
                    },
                    {
                        name: 'payments',
                        title: 'Pagos',
                        hasSiderLink: true,
                        component: Payments,
                        path: ExpansiveUrlPath.payments,
                    },
                    {
                        name: 'payments',
                        title: 'Pagos',
                        hasSiderLink: true,
                        component: Payments,
                        path: ExpansiveUrlPath.payments + '/:userTag',
                    },
                    {
                        name: 'founds',
                        title: 'Fondeo',
                        hasSiderLink: true,
                        component: FundingPage,
                        path:
                            ExpansiveUrlPath.summary +
                            '/' +
                            ExpansiveUrlPath.founds,
                    },
                    {
                        name: 'founds',
                        title: 'Fondeo',
                        hasSiderLink: true,
                        component: FundingPage,
                        path:
                            ExpansiveUrlPath.summary +
                            '/' +
                            ExpansiveUrlPath.founds +
                            '/:userTag',
                    },
                    {
                        name: 'withdrawal',
                        title: 'Retiros',
                        hasSiderLink: true,
                        component: WithdrawalPage,
                        path:
                            ExpansiveUrlPath.summary +
                            '/' +
                            ExpansiveUrlPath.withdrawal,
                    },
                    {
                        name: 'profile',
                        title: 'Perfil',
                        hasSiderLink: true,
                        component: Profile,
                        path: ExpansiveUrlPath.profile,
                    },
                    {
                        name: 'profile',
                        title: 'Perfil',
                        hasSiderLink: true,
                        component: Profile,
                        path: ExpansiveUrlPath.profile + '/:userTag',
                    },
                    {
                        name: 'privacy',
                        title: 'Privacidad',
                        hasSiderLink: true,
                        component: Privacy,
                        path: ExpansiveUrlPath.privacy,
                    },
                    {
                        name: 'privacy',
                        title: 'Privacidad',
                        hasSiderLink: true,
                        component: Privacy,
                        path: ExpansiveUrlPath.privacy + '/:userTag',
                    },
                    {
                        name: 'references',
                        title: 'Referidos',
                        hasSiderLink: true,
                        component: References,
                        path: ExpansiveUrlPath.references,
                    },
                    {
                        name: 'references',
                        title: 'Referidos',
                        hasSiderLink: true,
                        component: References,
                        path: ExpansiveUrlPath.references + '/:userTag',
                    },
                    {
                        name: 'investment',
                        title: 'Inversiones',
                        hasSiderLink: true,
                        component: Investments,
                        path: ExpansiveUrlPath.investment,
                    },
                    {
                        name: 'investment',
                        title: 'Inversiones',
                        hasSiderLink: true,
                        component: Investments,
                        path: ExpansiveUrlPath.investment + `/:userTag`,
                    },
                    {
                        name: 'investmentDetails',
                        title: 'Inversiones Resumen',
                        hasSiderLink: true,
                        component: InvestmentsResume,
                        path: ExpansiveUrlPath.investment + '/:id/detalles',
                    },
                    {
                        name: 'investmentDetails',
                        title: 'Inversiones Resumen',
                        hasSiderLink: true,
                        component: InvestmentsResume,
                        path:
                            ExpansiveUrlPath.investment +
                            '/:userTag/:id/detalles',
                    },
                    {
                        name: 'investmentFunds',
                        title: 'Inversiones Fondeo',
                        hasSiderLink: true,
                        component: PageFundingInvestment,
                        path:
                            ExpansiveUrlPath.investment +
                            '/:id/' +
                            ExpansiveUrlPath.founds,
                    },
                ],
            },
        ],
    },
];
