export const ExpansiveUrlPath = {
    summary: 'resumen',
    payments: 'pagos',
    references: 'referidos',
    investment: 'inversiones',
    privacy: 'privacidad',
    opportunity: 'oportunidades',
    investmentProcess: 'inversiones',
    profile: 'perfil',
    founds: 'fondeo',
    withdrawal: 'retiro',
    login: 'login',
    register: 'registro',
    registerDeveloper: 'registro-solicitante',
    activateAccount: 'activate',
    projects: 'proyectos',
    request: 'solicitudes',
    invite: 'codigo/referido',
    welcome: 'bienvenido',
};

export const InvestmentPath = {
    calculate: 'calculadora',
    contract: 'contrato',
    payment: 'pago',
    finish: 'finalizar',
};

//pages
export const WelcomeUrl = '/dashboard/' + ExpansiveUrlPath.welcome;
export const SummaryUrl = '/dashboard/' + ExpansiveUrlPath.summary;
export const PaymentsUrl = '/dashboard/' + ExpansiveUrlPath.payments;
export const ReferencesUrl = '/dashboard/' + ExpansiveUrlPath.references;
export const InvestmentUrl = '/dashboard/' + ExpansiveUrlPath.investment;
export const InvestmentProcess =
    '/dashboard/' + ExpansiveUrlPath.investmentProcess;
export const OpportunityUrl = '/dashboard/' + ExpansiveUrlPath.opportunity;
export const PrivacyUrl = '/dashboard/' + ExpansiveUrlPath.privacy;
export const ProfileUrl = '/dashboard/' + ExpansiveUrlPath.profile;
export const FoundsUrl =
    '/dashboard/' + ExpansiveUrlPath.summary + '/' + ExpansiveUrlPath.founds;
export const WithdrawalUrl =
    '/dashboard/' +
    ExpansiveUrlPath.summary +
    '/' +
    ExpansiveUrlPath.withdrawal;
export const ExpansiveLogin = '/' + ExpansiveUrlPath.login;
export const ExpansiveRegister = '/' + ExpansiveUrlPath.register;
export const ExpansiveRegisterDeveloper =
    '/' + ExpansiveUrlPath.registerDeveloper;
export const ExpansiveActivateAccount = '/' + ExpansiveUrlPath.activateAccount;
export const ExpansiveInvite = '/' + ExpansiveUrlPath.invite;

//Developer Url's
export const DeveloperSummary =
    '/desarrollador' + '/' + ExpansiveUrlPath.summary;

export const DeveloperProjects =
    '/desarrollador' + '/' + ExpansiveUrlPath.projects;

export const DeveloperProfile =
    '/desarrollador' + '/' + ExpansiveUrlPath.profile;

export const DeveloperRequest =
    '/desarrollador' + '/' + ExpansiveUrlPath.request;

export const DeveloperUrl =
    import.meta.env.VITE_DEVELOPER_PAGE + '/developer/dashboard';

export const ExpansiveName =
    'Expansive Realty, S.A.P.I. de C.V., Institución de Financiamiento Colectivo';
export const ExpansiveAddress = `<p className='!font-semibold'>${ExpansiveName}</p> <br />
Calle Blvd. Miguel de Cervantes Saavedra 169, <br />
Piso 14, Suite/Oficina 14-104, Granada, <br />
Miguel Hidalgo, 11520 Ciudad de México, CDMX.`;

export const ExpansiveContactEmail = 'contacto@expansive.mx';
