import { Outlet } from 'react-router-dom';

import { lazy } from 'react';

const Navbar = lazy(() =>
    import('@/components/organisms/Navbar').then(({ Navbar }) => ({
        default: Navbar,
    })),
);

export const LoadingDashboardLayout: React.FC = () => {
    return (
        <main className="flex flex-row overflow-hidden">
            <>
                <div className=" pl-[20%] pr-12 pt-6 bg-greige w-full h-screen overflow-auto">
                    <Outlet />
                </div>
            </>
            <Navbar />
        </main>
    );
};
