import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { default as dayjs } from 'dayjs';

interface Notification {
    _id: string;
    message: string;
    type: string;
    read: boolean;
    createdAt: Date;
}

interface notifyState {
    notifications: Notification[];
    numNotifications: number;
}
const initialState: notifyState = {
    notifications: [],
    numNotifications: 0,
}

export const notifySlice = createSlice({
    name: 'notify',
    initialState,
    reducers: {
        setNotification: (state, action: PayloadAction<Notification[]>) => {
            state.notifications = [];
            const notification = action.payload.map(el => {
                return {
                    _id: el._id,
                    message: el.message,
                    type: el.type,
                    read: el.read,
                    createdAt: dayjs(el.createdAt).toDate(),
                };
            });
            const numNotifications = action.payload.filter(el => !el.read).length;
            state.notifications.push(...notification);
            state.numNotifications = numNotifications;
        },
        addNotification: (state, action: PayloadAction<Notification>) => {
            state.notifications.unshift(action.payload);
            state.numNotifications += 1;
        },
        clearNotification: state => {
            state.notifications = [];
        },
    },
});

export const { setNotification, clearNotification, addNotification } =
    notifySlice.actions;
export const NotifySelector = (state: RootState) => state.notify;
export default notifySlice.reducer;
